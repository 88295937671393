import { AlertTriangle, Sparkle } from 'lucide-react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent
} from 'src/components/shad-base/dialog';
import { NODE_TYPES, ROUTES } from 'src/config';
import useMeasureStore, {
  MeasureStoreType
} from 'src/hooks/store/useMeasureStore';

export default function CollisionSurveyNotification({
  isCollisionSurveyComplete,
  waitForResults,
  completeYourData,
  surveyInviteIdentifier,
  purchasesBranch
}: {
  isCollisionSurveyComplete: boolean;
  waitForResults: boolean;
  completeYourData: boolean;
  surveyInviteIdentifier: string;
  purchasesBranch: {
    identifier: string;
    path: string;
  };
}) {
  const router = useRouter();
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    setAddNewMeasurementDialogOpen,
    setActiveNode
  }: MeasureStoreType = useMeasureStore();

  const title = !isCollisionSurveyComplete
    ? 'Complete the Survey'
    : completeYourData
      ? 'Complete your Collision Conference Footprint'
      : 'Stay tuned for the results of the Collision Survey!';
  const description = !isCollisionSurveyComplete
    ? `It looks like you haven't completed your Collision Conference Carbon Footprint Survey! In order to be entered into our giveaways,  make sure you submit your survey.`
    : completeYourData
      ? `Did you make any other purchases during Collision that you haven't uploaded yet? Add them to your inventory now. The more comprehensive your Collision Conference Footprint is, the higher your chances of winning the major giveaway.`
      : waitForResults &&
        'Thank you for completing the Collision survey and entering your data! You will be notified in the coming weeks if you are the winner of one of our giveaways. In the meantime, look out for the results of the full carbon footprint of the Collision Conference 2024 to see how to stack up against other companies. ';

  const secondaryDescription =
    !isCollisionSurveyComplete || completeYourData
      ? `The deadline to be entered into the draw is June 26th. Don't miss out on your chance to win!`
      : 'Results of the Collision Carbon Footprint Survey will be released on July 3rd.';
  const buttonText = !isCollisionSurveyComplete
    ? 'Take me to the survey'
    : completeYourData
      ? 'Start adding my data'
      : null;

  return (
    <div className="flex flex-col flex-nowrap">
      <div
        className="mb-md flex w-full cursor-pointer flex-col flex-nowrap rounded-md bg-primary p-sm shadow-md hover:bg-primary/90"
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        <div className="flex flex-nowrap items-center ">
          {!isCollisionSurveyComplete ? (
            <AlertTriangle className="mr-sm min-h-6 min-w-6 text-primary-foreground" />
          ) : (
            <Sparkle className="mr-sm min-h-6 min-w-6 text-primary-foreground" />
          )}

          <p className="font-bold text-primary-foreground">
            {!isCollisionSurveyComplete
              ? 'Finish Collision Survey'
              : completeYourData
                ? 'Complete your Collision Conference Footprint'
                : 'Stay tuned for Collision Survey Results'}
          </p>
        </div>
      </div>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent>
          <div className="flex flex-col flex-nowrap">
            <div className="mb-sm flex flex-nowrap items-center">
              <p className="subtitle1 ">{title}</p>
            </div>
            <p className="body1 mb-md">{description}</p>
            <p className="body1">{secondaryDescription}</p>
            {buttonText && (
              <div className="mt-md flex w-full justify-end">
                <Button
                  onClick={() => {
                    setDialogOpen(false);
                    if (!isCollisionSurveyComplete) {
                      router.push(
                        ROUTES.COLLISION_SURVEY.SURVEY(
                          surveyInviteIdentifier
                        )
                      );
                    } else if (completeYourData) {
                      if (
                        router.pathname !== ROUTES.INVENTORY.OVERVIEW
                      ) {
                        router.push(ROUTES.INVENTORY.OVERVIEW);
                      }
                      setAddNewMeasurementDialogOpen(true);
                      if (purchasesBranch?.identifier) {
                        setActiveNode({
                          nodeId: purchasesBranch.identifier,
                          type: NODE_TYPES.BRANCH,
                          path: purchasesBranch.path
                        });
                      }
                    }
                  }}
                >
                  {buttonText}
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
