import { useState } from 'react';
import useOnboarding, {
  OnboardingType
} from 'src/hooks/store/useOnboardingStore';
import OnboardingSectionSelector from 'src/components/core/molecules/Onboarding/OnboardingSectionSelector';
import { cn } from '@/lib/utils';
import { GraduationCap } from 'lucide-react';

export default function OnboardingSelector() {
  const [
    isOnboardingSectionSelectorOpen,
    setIsOnboardingSectionSelectorOpen
  ] = useState(false);

  const { isOnboardingComplete } = useOnboarding(
    (store: OnboardingType) => ({
      isOnboardingComplete: store.onboardingComplete
    })
  );
  return (
    !isOnboardingComplete && (
      <OnboardingSectionSelector
        isOpenState={[
          isOnboardingSectionSelectorOpen,
          setIsOnboardingSectionSelectorOpen
        ]}
        placement="bottom-end"
        renderCurrentSection={() => {
          return (
            <div
              className={cn(
                'flex w-full cursor-pointer flex-row items-center justify-between p-sm hover:bg-primary/10 rounded-md'
              )}
              onClick={() => setIsOnboardingSectionSelectorOpen(true)}
            >
              <div>
                <p>Learn </p>
              </div>
              <div>
                <GraduationCap className="h-icon w-icon text-primary" />
              </div>
            </div>
          );
        }}
      />
    )
  );
}
